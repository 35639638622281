import cx from "classnames"
import cloneDeep from "lodash/cloneDeep"
import getvalue from "lodash/get"
import { Fragment, useCallback, useEffect, useState } from "react"
import ErrorBoundary from "src/errors/ErrorBoundary"
import useTranslation from "src/hooks/useTranslation"
import Loading from "../Loading"
import Paginator from "./Paginator"
import styles from "./style.module.scss"

export default function AdvancedTable({
  data = [],
  className = "",
  isLoading = false,
  emptyText = "0 registros",
  displayedColumns = [],
  config: { rows, rowClassName, ...rowConfigs } = {},
  selectedClassName = null,
  onRowClick = (el) => {},
  topLeftContent = null,
  showPaginator = true,
  hideTopPaginator = false,
  stickyHeader = false,
  paginatorConfig = {
    columnSort: null,
    currentPage: 1,
    totalPages: 300,
    onNext: () => {},
    onBack: () => {},
    onChangeSort: (v) => {}
  },
  sortable = false,
  tableStyle = {},
  expanded = false,
  containerStyles = {},
  showRecordsCounter = false,
  hideHeader = false,
  theadRows = null,
  allowSubrows = false
}) {
  const [initData, setInitData] = useState(data)
  const [dataExt, setDataExt] = useState([])

  const { t } = useTranslation()

  const makeRowClassName = useCallback(
    (row = { isOpen: false }) => {
      const { isOpen } = row

      if (!isOpen) {
        return cx(
          styles.selectableRow,
          row?.id ? "record-" + row.id : null,
          typeof rowClassName === "function" ? rowClassName(row) : null
        )
      }

      return cx(
        styles.selectableRow,
        selectedClassName,
        row?.id ? "record-" + row.id : null,
        typeof rowClassName === "function" ? rowClassName(row) : null
      )
    },
    [selectedClassName]
  )

  useEffect(() => {
    setInitData(data)
  }, [data])

  const onRowClickHandler = (index) => {
    const updateData = cloneDeep(initData)

    updateData
      .filter((e, idx) => "isOpen" in e && e.isOpen && idx !== index)
      .forEach((e) => {
        e.isOpen = false
      })

    updateData[index].isOpen = true

    setInitData(updateData)

    onRowClick(initData[index])
  }

  const getRowConfig = (key, config, defaultValue = null) => {
    if (typeof rows === "undefined" || typeof rows[key] === "undefined" || typeof rows[key][config] === "undefined") {
      return defaultValue
    }

    return rows[key][config]
  }

  const onRowEnterHandler = (index) => {
    const updateData = [...initData]

    updateData[index].showCell = true

    setInitData(updateData)
  }

  const onRowLeaveHandler = (index) => {
    const updateData = [...initData]

    updateData[index].showCell = false

    setInitData(updateData)
  }

  const onBackHandler = () => {
    if (typeof paginatorConfig?.setPage !== "undefined") {
      paginatorConfig?.setPage((prev) => prev - 1)
    } else {
      paginatorConfig.onBack()
    }
  }
  const onNextHandler = () => {
    if (typeof paginatorConfig?.setPage !== "undefined") {
      paginatorConfig?.setPage((prev) => prev + 1)
    } else {
      paginatorConfig.onNext()
    }
  }

  const renderTableRowCell = (cell, object, idx, key) => {
    const getCellValue = () => {
      if (cell === false) {
        return null
      }

      if (typeof cell === "undefined" || cell === null) {
        return getvalue(object, key, "")
      }
      if (typeof cell === "function") {
        return cell(object, idx)
      }
      if (typeof cell === "string") {
        return getvalue(object, cell, "")
      }

      return null
    }

    let value = getCellValue()
    if (typeof value === "string") {
      const isHtml = rows[key]?.html
      if (isHtml) {
        value = <span dangerouslySetInnerHTML={{ __html: value }} />
      }
    }

    if (rows[key]?.isLink) {
      const href = rows[key]?.url(object)
      value = (
        <a href={href} className={rows[key]?.className}>
          {value}
        </a>
      )
    }

    return value
  }

  const renderRowProp = (prop, object, idx) => {
    if (prop === null || typeof prop === "undefined") {
      return null
    }
    if (typeof prop === "function") {
      return prop(object, idx)
    } else if (typeof prop === "string") {
      return getvalue(object, prop, "")
    }

    return prop
  }

  const sortStatus =
    !sortable || paginatorConfig.columnSort === null
      ? null
      : paginatorConfig.columnSort.substr(0, 1) == "-"
      ? { column: paginatorConfig.columnSort.substr(1), dir: "desc" }
      : { column: paginatorConfig.columnSort, dir: "asc" }

  const renderSortIndicatorIcon = (dir) => {
    switch (dir) {
      case "asc":
        return <i className='ui icon caret down' />
      case "desc":
        return <i className='ui icon caret up' />
    }
  }

  const renderSortIndicator = (key) => {
    if (sortStatus.column == key) {
      switch (sortStatus.dir) {
        case "asc":
          return (
            <div
              className={cx(styles.sortIndicator, styles.sortIndicatorDown)}
              onClick={() => paginatorConfig.onChangeSort("-" + sortStatus.column)}
              title='Ordenar descendentemente'
            >
              {renderSortIndicatorIcon("asc")}
            </div>
          )
        case "desc":
          return (
            <div
              className={cx(styles.sortIndicator, styles.sortIndicatorUp)}
              onClick={() => paginatorConfig.onChangeSort(sortStatus.column)}
              title='Ordenar ascendentemente'
            >
              {renderSortIndicatorIcon("desc")}
            </div>
          )
      }
    } else {
      return (
        <div
          className={cx(styles.sortIndicator, styles.sortIndicatorUndefined)}
          onClick={() => paginatorConfig.onChangeSort(key)}
          title='Ordenar ascendentemente'
        >
          <i className='ui icon sort' />
        </div>
      )
    }
  }

  const getRowElExtData = (idx, name, defaultValue = null) => {
    if (typeof dataExt[idx] === "undefined") {
      return defaultValue
    }

    return dataExt[idx][name] ?? defaultValue
  }

  const setRowElExtData = (idx, name, value) => {
    setDataExt((prev) => {
      let updatedData = [...prev]
      if (typeof updatedData[idx] === "undefined") {
        updatedData[idx] = {}
      }
      updatedData[idx][name] = value

      return updatedData
    })
  }

  const handleClickSubrowArrow = (el, idx) => {
    setRowElExtData(idx, "openSubrow", !getRowElExtData(idx, "openSubrow", false))
  }

  const renderSubrowArrow = (el, idx) => {
    return (
      <div className={styles.subrowArrow} onClick={() => handleClickSubrowArrow(el, idx)}>
        <i className={cx("icon large caret", getRowElExtData(idx, "openSubrow", false) ? "down" : "right")} />
      </div>
    )
  }

  const renderConsolidateRow = (rowEl, rowIndex) => {
    const curValue = getvalue(rowEl, paginatorConfig.consolidate.column, null)
    const nextEl = rowIndex + 1 >= initData.length ? null : initData[rowIndex + 1]
    const nextValue = nextEl === null ? null : getvalue(nextEl, paginatorConfig.consolidate.column, null)

    if (nextEl === null || nextValue !== curValue) {
      const countMatches = initData.filter((x) => {
        return getvalue(x, paginatorConfig.consolidate.column, null) === curValue
      }).length

      const renderCurValue = () => {
        const formatter = paginatorConfig.consolidate.formatCurValue
        return typeof formatter === "undefined"
          ? curValue
          : formatter === false
          ? null
          : paginatorConfig.consolidate.formatCurValue({
              currentValue: curValue,
              currentRow: rowEl,
              currentIndex: rowIndex,
              sortedColumn: paginatorConfig.consolidate.column
            })
      }

      return (
        <tr className={cx(styles.consolidateRow, paginatorConfig.consolidate.className, "consolidateRow")}>
          <td colSpan={displayedColumns.length}>
            <div className={styles.consolidateRowWrapper}>
              <div className={cx(styles.curValue, "curValue")}>{renderCurValue()}</div>
              <div className={cx(styles.curFn, "curFn")}>{t("main_ui.general.tableview.lb_consolidates_count")}: </div>
              <div className='ui label circular blue'>{countMatches}</div>
            </div>
          </td>
        </tr>
      )
    }

    return null
  }

  return (
    <ErrorBoundary>
      <div className={cx(styles.container, stickyHeader && styles.stickyHeader)} style={containerStyles}>
        {(showPaginator || topLeftContent) && (
          <div style={{ display: "flex" }}>
            {topLeftContent}
            <span style={{ flex: 1 }} />
            {!hideTopPaginator && (
              <Paginator
                currentPage={paginatorConfig.currentPage}
                totalPages={paginatorConfig.totalPages}
                onBack={onBackHandler}
                onNext={onNextHandler}
              />
            )}
          </div>
        )}
        <div className={cx(styles.tableWrapper, "tableWrapper", allowSubrows && styles.allowSubrows)}>
          <table
            style={tableStyle}
            className={cx(
              styles.table,
              (initData === null || initData.length == 0) && styles.tableEmpty,
              (initData === null || initData.length == 0) && "table-empty",
              expanded && styles["expanded-" + expanded],
              className
            )}
          >
            {hideHeader ? null : (
              <thead>
                {theadRows}
                <tr>
                  {displayedColumns.map((key) => {
                    if (getRowConfig(key, "headerCell") === false) {
                      return null
                    }

                    const sortColumnKey =
                      sortable && getRowConfig(key, "sortable") === true
                        ? key
                        : typeof getRowConfig(key, "sortable") == "string"
                        ? getRowConfig(key, "sortable")
                        : null

                    return (
                      <th
                        key={key}
                        className={cx(
                          sortable && sortStatus?.column == sortColumnKey && styles.sortActive,
                          getRowConfig(key, "sorted") && styles.sortActive
                        )}
                        style={{
                          width: getRowConfig(key, "width"),
                          ...getRowConfig(key, "headerCellStyle")
                        }}
                      >
                        {sortable && getRowConfig(key, "sortable") ? (
                          <div className={styles.sortWrapper}>
                            <div>{getRowConfig(key, "headerCell", t([`frontend.default.${key}`, key]))}</div>
                            {renderSortIndicator(sortColumnKey)}
                          </div>
                        ) : getRowConfig(key, "sorted") ? (
                          <div className={styles.sortWrapper}>
                            <div>{getRowConfig(key, "headerCell", t([`frontend.default.${key}`, key]))}</div>
                            <div className={cx(styles.sortIndicator, styles.disabled)}>
                              {renderSortIndicatorIcon(getRowConfig(key, "sorted"))}
                            </div>
                          </div>
                        ) : (
                          getRowConfig(key, "headerCell", t([`frontend.default.${key}`, key]))
                        )}
                      </th>
                    )
                  })}
                </tr>
              </thead>
            )}

            <tbody>
              {initData === null || initData.length == 0 ? (
                <tr>
                  <td colSpan={displayedColumns.length} className={styles.noContent}>
                    <div className='nocontent'>{isLoading ? <Loading curtain={false} /> : emptyText}</div>
                  </td>
                </tr>
              ) : (
                initData?.map((el, index) => {
                  const expandedDetails = rowConfigs?.expandedDetails
                    ? rowConfigs.expandedDetails(el)
                    : { enabled: false }

                  return (
                    <Fragment key={el.id ? el.id : index}>
                      <tr
                        onClick={onRowClickHandler.bind(this, index)}
                        onMouseEnter={onRowEnterHandler.bind(this, index)}
                        onMouseLeave={onRowLeaveHandler.bind(this, index)}
                        className={makeRowClassName(el)}
                      >
                        {displayedColumns.map((key, _idx) => (
                          <td
                            key={key}
                            style={{
                              width: getRowConfig(key, "width"),
                              transition: "opacity 300ms ease-in-out",
                              ...renderRowProp(getRowConfig(key, "cellStyle"), el, index)
                            }}
                            className={cx(
                              {
                                hideCell: getRowConfig(key, "showOnHover") && !el.showCell,
                                showCell: getRowConfig(key, "showOnHover") && el.showCell
                              },
                              "cell-" + key,
                              getRowConfig(key, "showOnHover") ? (el.showCell ? styles.showCell : styles.hideCell) : "",
                              getRowConfig(key, "className")
                            )}
                          >
                            <ErrorBoundary>
                              {allowSubrows && _idx == 0 && expandedDetails?.enabled && renderSubrowArrow(el, index)}
                              <>{renderTableRowCell(getRowConfig(key, "cell"), el, index, key)}</>
                            </ErrorBoundary>
                          </td>
                        ))}
                      </tr>
                      {typeof paginatorConfig?.consolidate !== "undefined" && renderConsolidateRow(el, index)}

                      {expandedDetails.enabled && getRowElExtData(index, "openSubrow", false) && (
                        <tr>
                          <td colSpan={displayedColumns.length}>
                            <div>{expandedDetails.render()}</div>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                })
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>

        {showRecordsCounter && initData.length > 0 && (
          <div style={{ marginTop: 5, textAlign: "right" }}>
            {initData.length} registro{initData.length == 1 ? "" : "s"}
          </div>
        )}
        {showPaginator && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ flex: 1 }} />
            <Paginator
              currentPage={paginatorConfig.currentPage}
              totalPages={paginatorConfig.totalPages}
              onBack={onBackHandler}
              onNext={onNextHandler}
            />
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
