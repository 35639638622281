import useTranslation from "src/hooks/useTranslation"
import PaginatorButton, { arrowDirection } from "./PaginatorButton"
import style from "./style.module.scss"

export default function Paginator({ currentPage = 1, totalPages = 230, onBack = () => {}, onNext = () => {} }) {
  const { t } = useTranslation()

  const onClickHandler = (button) => {
    switch (button) {
      case arrowDirection.LEFT:
        if (currentPage === 1) return

        onBack()
        break
      case arrowDirection.RIGHT:
        if (currentPage === totalPages) return

        onNext()
        break
    }
  }

  return (
    <div className={style.pagination}>
      <div className={style.buttons}>
        <PaginatorButton
          disabled={currentPage == 1}
          direction={arrowDirection.LEFT}
          onClick={onClickHandler.bind(this, arrowDirection.LEFT)}
        />
        <div className={style.text}>{t("main_ui.general.pagination_template", { currentPage, totalPages })}</div>
        <PaginatorButton
          disabled={currentPage == totalPages}
          direction={arrowDirection.RIGHT}
          onClick={onClickHandler.bind(this, arrowDirection.RIGHT)}
        />
      </div>
    </div>
  )
}
