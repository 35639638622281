import cx from "classnames"
import Icon from "../../../../components/Icon"
import style from "../style.module.scss"

export const arrowDirection = Object.freeze({
  LEFT: "LEFT",
  RIGHT: "RIGHT"
})

export default function PaginatorButton({ direction = arrowDirection.RIGHT, onClick = () => {}, disabled = false }) {
  let transform = null

  switch (direction) {
    case arrowDirection.LEFT:
      transform = "rotateZ(90deg)"
      break
    case arrowDirection.RIGHT:
      transform = "rotateZ(-90deg)"
      break
  }

  return (
    <div
      onClick={disabled ? null : onClick}
      className={cx(style.button, disabled && [style.disabled, "disabled"])}
      style={{ transform }}
    >
      <Icon name='arrow' size={15} color={disabled ? "#999" : "#0a858d"} />
    </div>
  )
}
